<template>
  <div class="d-flex">
    <v-text-field
      ref="inputRef"
      solo
      flat
      dense
      outlined
      clearable="clearable"
      hide-details="auto"
      :disabled="disabled"
      :placeholder="isEmpty(price) ? $t('label.empty') : leadPriceDisplay"
      :value="price"
      @click:clear="$emit('clear')"
    />
  </div>
</template>

<script>
import { isEmpty, formattedPrice } from '@/utils/helper.util'
import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'UiInputPrice',
  props: {
    currency: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    locale: {
      type: String,
      required: true,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { inputRef, numberValue } = useCurrencyInput(
      {
        currency: props.currency,
        currencyDisplay: 'symbol',
        locale: props.locale,
        precision: 2,
        useGrouping: true,
        accountingSign: false,
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
      },
      false
    )

    return { inputRef, numberValue }
  },
  watch: {
    numberValue: {
      handler(newPrice, oldPrice) {
        if (newPrice !== oldPrice) this.$emit('onPriceChange', newPrice)
      },
      immediate: false,
    },
  },
  computed: {
    leadPriceDisplay() {
      return formattedPrice({
        number: this.price * 100,
        currency: this.currency,
        digit: 2,
      })
    },
  },
  methods: {
    isEmpty,
  },
}
</script>
