<template>
  <div v-if="editMode" class="tw-mt-3 d-flex">
    <ui-input-price
      :price="price"
      :currency="field.value.currency"
      :disabled="!editable"
      :locale="locale"
      @onPriceChange="onPriceChange"
    />
  </div>

  <div v-else>
    <span class="empty-field" v-if="!parsedValue">{{ $t('label.empty') }}</span>
    <span v-else v-dompurify-html="parsedValue" />
  </div>
</template>

<script>
import { isEmpty, formattedPrice } from '@/utils/helper.util'
import UiInputPrice from '@/components/UI/input/Price.vue'

export default {
  name: 'LeadsIndividualFieldsPrice',
  props: {
    field: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    locale: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    UiInputPrice,
  },
  data: () => ({
    priceRef: null,
    price: null,
    editedValue: null,
  }),
  created() {
    const itemValue = this.field.value?.value
    if (itemValue) {
      this.price = itemValue / 100
    }
  },
  computed: {
    parsedValue() {
      if (!this.field.value?.value) return undefined
      const number = this.field.value.value
      return formattedPrice({ number, currency: this.field.value.currency })
    },
  },
  methods: {
    isEmpty,
    onPriceChange(price) {
      this.editedValue = { value: price * 100, currency: this.field.value.currency }
    },
  },
  watch: {
    editedValue: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.$emit('updateField', { [this.field.slug]: newValue })
      },
      deep: true,
      immediate: false,
    },
  },
}
</script>
